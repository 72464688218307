import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../layout/breadcrumb';
import { Container, Row, Col, Card, CardBody, Input } from 'reactstrap';
import {
  getVendingItemList,
  merchantId_api,
  updateVendingItemList,
  vendingItemDelete,
} from '../lib/api';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import AddVendingItemModal from '../components/modal/addVendingItemModal';
import { vendingMachine } from '../lib/socket';
import { AddBtn, Label1 } from './lockerChange';
import { LabelRow } from './ticketCustom';
import configDB from '../data/customizer/config';
import PageContainer from '../components/common/PageContainer';

import { Button } from '../components/common/Button';

import IconAdd from '../assets/images/button/icon_button_add_inverted.svg';

const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;

const AddBtnBox = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 20px;
  > button {
    margin: 0;
  }
  @media screen and (max-width: 768px) {
    padding: 10px 10px 0;
  }
`;

const VendingContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
    .inputsContainer {
      width: 100%;
      label {
        width: 50%;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .inputsContainer {
      label {
        width: 100%;
      }
    }
  }
`;

const VendingItem = styled.div`
  width: 100%;
  padding: 20px;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .imgContainer {
    width: 100%;
    max-height: 250px;
    /* height: 100%; */

    img {
      width: 100%;
      max-height: 250px;
      object-fit: contain;
    }
  }
  ${LabelRow} {
    margin: 10px 0;
    h5 {
      width: 20%;
    }
    input {
      width: 80%;
      margin-top: 0;
    }
  }
`;

let mid;
const Vending = ({ setIsLoading }) => {
  const [vendingItems, setVendingItems] = useState([]);
  const [item, setItem] = useState({
    vending_number: '',
    vending_name: '',
    vending_count: '',
    vending_price: '',
    vending_image: '',
    merchantId: '',
  });
  const [isModal, setIsModal] = useState();
  var regex = /[^0-9]/g;
  const getMerchantId = async () => {
    const data = await merchantId_api();

    if (data.status === 200 && data.data) {
      getVendingItem(data.data.id);
      mid = data.data.id;
    }
  };

  const getVendingItem = async (mid) => {
    setIsLoading(true);
    const res = await getVendingItemList(mid);

    if (res.status === 200) {
      setVendingItems(res.data);
    }
    setIsLoading(false);
  };

  const handleChange = (e) => {
    let copyData = [...vendingItems];
    if (
      e.target.name.includes('count') ||
      e.target.name.includes('number') ||
      e.target.name.includes('price')
    ) {
      setVendingItems(
        copyData.map((item) =>
          item.id === +e.target.id
            ? { ...item, [e.target.name]: e.target.value.replace(regex, '') }
            : item,
        ),
      );
    } else {
      setVendingItems(
        copyData.map((item) =>
          item.id === +e.target.id ? { ...item, [e.target.name]: e.target.value } : item,
        ),
      );
    }
  };

  const updateVendingItem = async (idx) => {
    //이미지가 url이 아닌경우(앞에 "http"가 아닌경우)
    if (
      vendingItems[idx].vending_image &&
      vendingItems[idx].vending_image.substring(0, 4) !== 'http'
    ) {
      return Swal.fire({
        icon: 'error',
        title: '이미지 url이 아닙니다. 확인 후 다시 시도해주세요',
        timer: 2000,
      });
    }
    const result = await updateVendingItemList(vendingItems[idx]);

    if (result.status === 200) {
      Swal.fire({
        icon: 'success',
        title: '수정이 완료되었습니다.',
        timer: 2000,
      }).then(getVendingItem(mid));
    } else {
      Swal.fire({
        icon: 'error',
        title: '수정중 에러가 발생했습니다.',
        timer: 2000,
      });
    }
  };

  const deleteVendingItem = async (idx) => {
    Swal.fire({
      title: '자판기 아이템을 삭제하시겠습니까?',
      text: '삭제를 원하실경우 삭제버튼을 눌러주세요',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F53C14',
      cancelButtonColor: '#04c9db',
      confirmButtonText: '삭제',
      cancelButtonText: '취소',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await vendingItemDelete(vendingItems[idx].merchantId, vendingItems[idx].id);

        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: '아이템 삭제가 완료되었습니다.',
            timer: 3000,
          }).then(() => getVendingItem(mid));
        } else {
          Swal.fire({
            icon: 'error',
            title: '삭제중 에러가 발생했습니다. 다시 시도해주세요',
            timer: 3000,
          });
        }
      }
    });
  };

  const manualButton = (idx) => {
    Swal.fire({
      title: '자판기 아이템을 수동출력하시겠습니까?',
      text: '출력를 원하실경우 출력버튼을 눌러주세요',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F53C14',
      cancelButtonColor: '#04c9db',
      confirmButtonText: '출력',
      cancelButtonText: '취소',
    }).then(async (result) => {
      if (result.isConfirmed) {
        vendingMachine(mid, vendingItems[idx].vending_number);
        Swal.fire({
          icon: 'success',
          title: '아이템이 수동 출력됩니다.',
          timer: 3000,
        });
      }
    });
  };
  const openModal = () => {
    setIsModal(!isModal);
  };

  useEffect(() => {
    getMerchantId();
  }, []);
  return (
    <>
      {isModal && (
        <AddVendingItemModal
          item={item}
          setItem={setItem}
          close={openModal}
          merchant={mid}
          setVendingItems={getVendingItem}
        />
      )}

      <Fragment>
        <PageContainer
          title="자판기 상품 관리"
          itemsRightSideTitle={[
            <Button icon={IconAdd} onClick={() => openModal()} buttonType="default">
              자판기 상품등록
            </Button>,
          ]}>
          {vendingItems.length > 0 ? (
            <VendingContainer>
              {vendingItems.map((item, index) => (
                <VendingItem key={item?.id + index + 'vtem'}>
                  <div className="imgContainer">
                    <img
                      src={
                        item?.vending_image
                          ? item?.vending_image
                          : require('../assets/images/logo/logo512.png')
                      }
                      alt="자판기 아이템"
                    />
                  </div>
                  <div className="inputsContainer">
                    <LabelRow>
                      <h5 style={{ minWidth: '4rem' }}>상품번호 </h5>
                      <Input
                        value={item?.vending_number || ''}
                        name="vending_number"
                        id={item?.id || ''}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </LabelRow>
                    <LabelRow>
                      <h5 style={{ minWidth: '4rem' }}>상품명 </h5>
                      <Input
                        id={item?.id || ''}
                        name="vending_name"
                        value={item?.vending_name || ''}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </LabelRow>
                    <LabelRow>
                      <h5 style={{ minWidth: '4rem' }}>상품가격 </h5>
                      <Input
                        id={item?.id || ''}
                        name="vending_price"
                        value={item?.vending_price || ''}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </LabelRow>
                    <LabelRow>
                      <h5 style={{ minWidth: '4rem' }}>상품수량 </h5>
                      <Input
                        id={item?.id || ''}
                        name="vending_count"
                        value={item?.vending_count || 0}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </LabelRow>

                    <Label1 style={{ width: '100%', margin: '15px 0' }}>
                      <h5>상품 이미지 url </h5>
                      <Input
                        id={item?.id || ''}
                        name="vending_image"
                        style={{ width: '100%' }}
                        value={item?.vending_image || ''}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </Label1>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        gap: '10px',
                      }}>
                      <Button striped onClick={() => manualButton(index)} buttonType="default">
                        수동출력
                      </Button>
                      <Button onClick={() => updateVendingItem(index)} buttonType="default">
                        수정
                      </Button>
                      <Button
                        color="#F53C14"
                        onClick={() => deleteVendingItem(index)}
                        buttonType="warning">
                        삭제
                      </Button>
                    </div>
                  </div>
                </VendingItem>
              ))}
            </VendingContainer>
          ) : (
            <h5 style={{ textAlign: 'center' }}>등록된 상품이 없습니다.</h5>
          )}
        </PageContainer>
      </Fragment>
    </>
  );
};

export default Vending;
