import moment from 'moment';
import React from 'react';

import { useState } from 'react';
import { ArrowRight, Info, LogOut, MessageSquare } from 'react-feather';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { getMapViewList, ticketForceExit, ticketUsing, userTicketBlock } from '../../lib/api';

import ChangeModal from './changeModal';
import DetailsModal from './detailsModal';
import MessageSendModal from './messageSendModal';
import { Text } from '../../style/textStyle';
import useHandleAPICall from '../../lib/hooks/useHandleAPICall';
import useHandleError from '../../lib/hooks/useHandleError';

const Btns = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
`;
export const ButtonS = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 24%;
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  background-color: ${(props) => props.bg};
  color: ${(props) => (props.color ? props.color : '#fff')};
  font-weight: 600;
  font-size: 15px;
  > svg {
    height: 17px;
  }
  @media screen and (max-width: 480px) {
    padding: 6px 10px;
    font-size: 13px;
    > svg {
      height: 15px;
    }
  }
`;

export const ModalBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 101;
`;
const ChoiceModal = ({ isOpen, onClose, seatData, setLists, lists, floorNum }) => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();
  const [changeSeatModal, setChangeSeatModal] = useState(false);
  const [detailUserModal, setDetailUserModal] = useState(false);
  const [isSendMsg, setIsSendMsg] = useState(false);
  const showChangeSeatModal = () => {
    setChangeSeatModal(!changeSeatModal);
  };

  const showDetailModal = () => {
    setDetailUserModal(!detailUserModal);
  };
  const toggleIsSendMsg = () => {
    setIsSendMsg(!isSendMsg);
  };

  const outTicket = async () => {
    let nowTime = moment();
    let diffTime = moment.duration(nowTime.diff(seatData.endDate)).asMilliseconds();
    if (diffTime > 0) {
      Swal.fire(
        '이미 만료되어 퇴장처리가 불가능한 이용자 입니다.',
        '초과결제 취소가 필요한경우 퇴장여부의 초과결제 취소를 눌러주세요',
      );
    } else {
      Swal.fire({
        title: '퇴장처리를 하시겠습니까?',
        text: '세미나실의 경우, 예약취소되어 현황에서도 사라집니다.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F53C14',
        cancelButtonColor: '#04c9db',
        confirmButtonText: '퇴장',
        cancelButtonText: '취소',
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (seatData.type === 4) {
            try {
              await createRequest(userTicketBlock, {
                ticketId: seatData.id,
                merchantId: seatData.merchantId,
                type: 4,
                block: !seatData.block,
              });
              const data = await createRequest(ticketUsing, seatData.userId, seatData.merchantId);
              await createRequest(ticketForceExit, {
                merchantId: seatData.merchantId,
                id: seatData.type === 4 ? seatData.id : seatData.ticketId,
                userId: seatData.userId,
                seatId: data.data.seatId,
              });
              Swal.fire('퇴장처리가 완료되었습니다.');
              getSeatListApi();
              onClose();
            } catch (error) {
              catchError(error, '퇴장처리 중 에러가 발생했습니다. 다시 시도해 주세요.');
            }
          } else {
            try {
              const data = await createRequest(ticketUsing, seatData.userId, seatData.merchantId);
              await createRequest(ticketForceExit, {
                merchantId: seatData.merchantId,
                id: seatData.type === 4 ? seatData.id : seatData.ticketId,
                userId: seatData.userId,
                seatId: data.data.seatId,
              });
              Swal.fire('퇴장처리가 완료되었습니다.');
              getSeatListApi();
              onClose();
            } catch (error) {
              catchError(error, '퇴장처리 중 에러가 발생했습니다. 다시 시도해 주세요.');
            }
          }
        }
      });
    }
  };

  const getSeatListApi = async () => {
    try {
      const res = await createRequest(getMapViewList, seatData.merchantId);
      if (floorNum) {
        setLists(
          res.data
            .filter((item) => item.type === 'seat' || item.type === 'seminar')
            .filter((list) => list.floor === +floorNum),
        );
      } else {
        setLists(res.data.filter((item) => item.type === 'seat' || item.type === 'seminar'));
      }
    } catch (error) {
      catchError(error);
    }
  };
  return (
    <>
      {detailUserModal && (
        <DetailsModal
          isOpen={detailUserModal}
          close={showDetailModal}
          choiceModalClose={onClose}
          seat={seatData}
        />
      )}
      {changeSeatModal && (
        <ChangeModal
          isOpen={changeSeatModal}
          close={showChangeSeatModal}
          choiceModalClose={onClose}
          seat={seatData}
          setLists={setLists}
          lists={lists}
        />
      )}
      {isSendMsg && (
        <MessageSendModal
          isOpen={isSendMsg}
          onClose={toggleIsSendMsg}
          phoneNum={seatData.phoneNum}
          mid={seatData.merchantId}
        />
      )}
      <Modal centered isOpen={isOpen} toggle={onClose}>
        <ModalHeader className="modal-title" toggle={onClose}>
          {seatData.type !== 4 ? <>{seatData.number}번 좌석</> : '세미나실 이용'} 회원 정보
        </ModalHeader>
        <ModalBody>
          <h5>{seatData.phoneNum}</h5>
          <Btns>
            {seatData.type !== 4 && seatData.type !== 'seminar' && (
              <ButtonS bg=" #FFC42B" color="#0F2A4A" onClick={showChangeSeatModal}>
                좌석이동 <ArrowRight />
              </ButtonS>
            )}

            <ButtonS bg="#21D7D9" color="#0F2A4A" onClick={toggleIsSendMsg}>
              문자발송 <MessageSquare />
            </ButtonS>
            <ButtonS bg="#722DE2 " onClick={showDetailModal}>
              상세정보 <Info />
            </ButtonS>
            <ButtonS bg="#F53C14" onClick={outTicket}>
              퇴장 <LogOut />
            </ButtonS>
          </Btns>
        </ModalBody>
      </Modal>
      <ModalBackground />
    </>
  );
};

export default ChoiceModal;
